import {createStore,applyMiddleware,combineReducers} from 'redux'
import thunk from 'redux-thunk'
import illustrationReducer from './reducers/illustrationReducer'


const reducers = combineReducers({
  illustration:illustrationReducer
})


const store = createStore(reducers, applyMiddleware(thunk))


export default store