import 'antd/dist/reset.css';
import './App.css';
import { BrowserRouter, Routes,Route, Outlet } from 'react-router-dom';
import React from 'react';

function App() {

  const Home = React.lazy(() => import('./pages/Home'));

  //client pages
  const Illustration = React.lazy(() => import('./pages/client/Illustration'));
  const IllustrationMoment = React.lazy(()=> import('./pages/client/IllustrationMoment'))
  const IllustrationText = React.lazy(()=> import('./pages/client/IllustrationText'))
  const IllustrationSilhouette = React.lazy(()=> import('./pages/client/IllustrationSilhouette'))
  const IllustrationDownload = React.lazy(()=> import('./pages/client/IllustrationDownload'))
  
  //admin pages
  const Dashboard = React.lazy(() => import('./pages/admin/Dashboard'));

  const Orders = React.lazy(()=> import('./pages/admin/orders/Orders'))
  const EditOrder =  React.lazy(()=> import('./pages/admin/orders/EditOrder'))

  const ArtBoard = React.lazy(() => import('./pages/admin/designs/ArtBoard'));
  const CreateTemplate = React.lazy(() => import('./pages/admin/designs/CreateTemplate'));
  const DesignStudio = React.lazy(() => import('./pages/admin/designs/DesignStudio'));
  const DesignCategories = React.lazy(()=>import('./pages/admin/designs/DesignCategories'))
  const AdminPreview = React.lazy(() => import('./pages/admin/designs/AdminPreview'));
  
  const PreviewTemplate = React.lazy(() => import('./pages/admin/PreviewTemplate'));
  //config pages
  const FontLibrary = React.lazy(() => import('./pages/admin/configs/FontLibrary'));
  /* const Integration = React.lazy(() => import('./pages/config/Integration'));
  const General = React.lazy(() => import('./pages/config/General')); */

  const AdminBackend = () => {
    return(
      <div className='bg-white min-h-screen w-full'>
        <Outlet />
      </div>
    )
  }

  return (
      <BrowserRouter>
        <Routes>
        
          <Route index element={<React.Suspense> <Dashboard /> </React.Suspense>} />

          <Route path="illustration">
            <Route path=":id" element={<React.Suspense> <Illustration /> </React.Suspense>} />
            <Route path="template/:id" element={<React.Suspense> <Illustration /> </React.Suspense>} />
            
            <Route path='moment/:id' element={<React.Suspense> <IllustrationMoment /> </React.Suspense>} />
            <Route path='text/:id' element={<React.Suspense> <IllustrationText /> </React.Suspense>} />
            <Route path='silhouette/:id' element={<React.Suspense> <IllustrationSilhouette /> </React.Suspense>} />
            
            <Route path='download/:id' element={<React.Suspense> <IllustrationDownload /> </React.Suspense>} />
          </Route>

          <Route path="admin" element={<AdminBackend />}>
            <Route index element={<React.Suspense> <Dashboard /> </React.Suspense>} />
            <Route path="orders" element={<React.Suspense> <Orders /> </React.Suspense>} />
            <Route path="order/:id" element={<React.Suspense> <EditOrder /> </React.Suspense>} />

            <Route path="categories" element={<React.Suspense> <DesignCategories /> </React.Suspense>} />
            <Route path='designs'>
              <Route index element={<React.Suspense> <DesignStudio /> </React.Suspense>} />
              <Route path=":id" element={<React.Suspense> <DesignStudio /> </React.Suspense>} />
            </Route>
            <Route path="create/:id" element={<React.Suspense> <CreateTemplate /> </React.Suspense>} />
            <Route path="artboard/:id" element={<React.Suspense> <ArtBoard /> </React.Suspense>} />
            <Route path="preview/:id" element={<React.Suspense> <AdminPreview /> </React.Suspense>} />
          </Route>

          <Route path='configs' element={<AdminBackend />}>
            {/* <Route index element={<React.Suspense> <General /> </React.Suspense>} />
            <Route path="integration" element={<React.Suspense> <Integration /> </React.Suspense>} /> */}
            <Route path="fontLibrary" element={<React.Suspense> <FontLibrary /> </React.Suspense>} />
          </Route>

        </Routes>
      </BrowserRouter>
  );
}

export default App;
